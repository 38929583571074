import { ApiService } from '../api';
import { URLS, UrlUtils } from '../urls';
import { L10nUtils } from '../../utils/l10n_utils';
const storedButtonSettings = new Map();
const storedPurchasingFlowSettings = new Map();
const storedProductInfo = new Map();
export class ProductInfoService extends ApiService {
    getView(code) {
        const currentLang = L10nUtils.getCurrentLanguage();
        const productInfo = ProductInfoService.getProductInfo(code);
        const url = UrlUtils.insertFewValuesToUrl(URLS.PRODUCT_VIEW, { code, lang: currentLang });
        if (productInfo) {
            return Promise.resolve(productInfo);
        }
        return this.get(url)
            .then(resp => resp.json())
            .then(product => {
            ProductInfoService.setProductInfo(product.identifier, product);
            return product;
        });
    }
    getEbayAffiliateInfo(item) {
        const url = UrlUtils.insertValueToUrl(URLS.PRODUCT_DETAILS, item.code || item.identifier);
        return this.get(url)
            .then(resp => resp.text())
            .then(resp => JSON.parse(resp));
    }
    getCustomButtonsSettings() {
        const currentLang = L10nUtils.getCurrentLanguage();
        const buttonSettings = ProductInfoService.getButtonsInfo();
        const url = UrlUtils.insertValueToUrl(URLS.CUSTOM_BUTTONS, currentLang);
        if (buttonSettings) {
            return Promise.resolve(buttonSettings);
        }
        return this.get(url)
            .then(resp => resp.json())
            .then(buttons => {
            ProductInfoService.setButtonsInfo(buttons);
            return ProductInfoService.getButtonsInfo();
        });
    }
    getPurchasingFlowSettings() {
        const purchasingFlowSettings = ProductInfoService.getPurchasingFlowSettings();
        if (purchasingFlowSettings) {
            return Promise.resolve(purchasingFlowSettings);
        }
        return this.get(URLS.PURCHASING_FLOW_SETTINGS)
            .then(resp => resp.json())
            .then((settings) => {
            ProductInfoService.setPurchasingFlowSettings(settings);
            return ProductInfoService.getPurchasingFlowSettings();
        });
    }
    static clearProductInfoCache() {
        storedProductInfo.clear();
    }
    static clearCustomButtonsSettingsCache() {
        storedButtonSettings.clear();
    }
    static getProductInfo(productIdentifier) {
        return storedProductInfo.get(productIdentifier);
    }
    static setProductInfo(productIdentifier, productData) {
        storedProductInfo.set(productIdentifier, productData);
    }
    static getButtonsInfo() {
        return storedButtonSettings.get('buttons');
    }
    static setButtonsInfo(buttons) {
        storedButtonSettings.set('buttons', buttons);
    }
    static getPurchasingFlowSettings() {
        return storedPurchasingFlowSettings.get('purchasingFlow');
    }
    static setPurchasingFlowSettings(settings) {
        return storedPurchasingFlowSettings.set('purchasingFlow', settings);
    }
}
