import Cookies from 'js-cookie';
import pipe from 'callbag-pipe';
import { Ray, Vector3 } from 'three';
import subscribe from 'callbag-subscribe';
import { Planogram } from './planogram';
import { SphereItems } from './sphere_items';
import { CameraControls } from './controls/camera_controls';
import { Overlay } from './overlay';
import { Fullscreen } from './fullscreen';
import Router from './router';
import { InputEventUtils } from './utils/input_event_utils';
import { URLS, UrlUtils } from './api/urls';
import { FontLoader } from './font_loader';
import { CookiesManagement } from './cookies_management';
import { ECOMMERCE_BUTTON_TYPE, HEATMAP_EVENT_TYPES, PLANOGRAM_NAME } from './shared/constants';
import { SphereItemType } from 'shared/interfaces/planogram';
import { NAVIGATION_BUTTON_TYPE } from './interfaces/planogram.interface';
import { WebUtils } from './utils/web_utils';
import { MATOMO_EVENT_NAMES } from './metric-events';
import { SEARCH_EVENT_NAMES as SEARCH_EVENTS, SPHERE_EVENT_NAMES, SPHERE_EVENT_NAMES as EVENTS } from './event-names';
import { Metrics } from './metrics';
import { searchEventHandler, sphereEventHandler } from './custom_event_utils';
import { AppUtils } from './utils/app_utils';
import { AppState } from './shared/app.state';
import { EntranceGuide } from './entrance-guide';
import { ProductInfoService } from './api/services/product_info.service';
import { Account } from './account/account';
import { Search } from './search/search';
import { SearchService } from './api/services/search.service';
import { InputHandler } from './input_handler';
import { L10nUtils } from './utils/l10n_utils';
import { L10nButtonComponent } from './components/l10n-button.component';
import { HeatMapService } from './api/services/heatmap.service';
import { ALIGNMENT_TYPES } from './interfaces/planogram.interface';
import { ActionType } from 'shared/interfaces/planogram';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { ShoppingCartService } from './api/services/shopping-cart.service';
import { CurrencySwitcher } from './components/currency-switcher/currency-switcher';
import { CurrencyService } from './api/services/currency.service';
import { KeyboardControls } from './controls/keyboard_controls';
import { MouseControls } from './controls/mouse_controls';
import { TouchControls } from './controls/touch_controls';
import loadingService, { LOADING_STAGES } from './api/services/loading_progress.service';
import { invertYAxis, normalizeMouse } from './utils/math_utils';
import { BrowserUtils } from './utils/browser_utils';
import { VideoLimiter } from './vt/video_limiter';
import { SphereShape } from 'shared/utils/SphereShape';
import { ShoppingCartUtils } from './utils/shopping-cart_utils';
import { SphereAudio } from './sphere_audio';
import { HTMLUtils } from './utils/html_utils';
import CanvasRenderer from './canvas_renderer';
import { NavigationArrows } from './navigation-arrows/navigation-arrows.component';
import { isTouchSupported } from './utils/platform';
import { FPSMeter } from './fps_meter';
import { hasUrlFlag } from 'shared/utils/debug';
import { IdealCartService } from './api/services/ideal-cart.service';
const AFTER_LOAD_ANIMATION_DELAY = 500;
export class SphereApp {
    constructor(params) {
        this.params = params;
        this.firstLoad = true;
        this.afterLoadQueue = [];
        this.isSphereLoaded = false;
        this.videos = [];
        this.cameraDirection = new Vector3();
        this.canvas = this.params.canvas;
        this.previousPlanogram = '';
        this.resetAutoplayRef = this.resetAutoplay.bind(this);
        this.resetNavigationRef = this.resetNavigation.bind(this);
        this.handleZoomButtonClickRef = this.handleZoomButtonClick.bind(this);
        this.pageInfoRef = this.pageInfo.bind(this);
        this.handleBlurCanvas = this.handleBlurCanvas.bind(this);
        this.hanldeUnblurCanvas = this.hanldeUnblurCanvas.bind(this);
        this.setCursor = this.setCursor.bind(this);
        this.pagePlanogramRef = item => {
            if (!item.itemData) {
                return;
            }
            this.pagePlanogram();
        };
        this.addRoutes();
        Router.onBeforeRoute(() => {
            this.updateNavigationMenuVisibility();
        });
        const sphereLogoButton = document.getElementById('sphere-logo');
        if (sphereLogoButton) {
            InputEventUtils.addSelectEvents(sphereLogoButton, () => {
                Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.CLICK_LOGO);
                this.cameraControls.clearAnimation();
                this.handleLogoClick();
            });
        }
        window.addEventListener('pageshow', () => {
            this.updateNavigationMenuVisibility();
        });
        if (hasUrlFlag('fps'))
            this.fpsMeter = new FPSMeter(document.getElementById('debug-panel'));
    }
    onRender(dt) {
        const pauseUpdates = this.overlay.isActive && !this.overlay.isJourneyOverlay;
        this.canvasRenderer.pauseLodUpdates(pauseUpdates);
        if (pauseUpdates) {
            return;
        }
        this.videoLimiter.update(dt);
        this.cameraControls.updateAnimations();
        sphereEventHandler.emit(EVENTS.SPHERE.UPDATE);
        this.videos.forEach(item => item.update(dt));
    }
    initApp() {
        this.heatMapService = new HeatMapService(this.planogram.versionId, window.screen.width, window.screen.height, this.getPlanogramCoordinates.bind(this), document.cookie);
        this.setupForegroundElements();
        this.searchService = new SearchService();
        this.search = new Search(this.searchService, this.planogram, this.inputHandler);
    }
    initializeServices() {
        this.storeCredentialCookies();
        const { ecommerceEnabled } = this.planogram;
        const checkoutId = `checkout-${this.planogram.clientName}`;
        if (ecommerceEnabled) {
            this.initCurrencySwitcher();
            this.initShoppingCart();
        }
        else if (!ecommerceEnabled && ShoppingCartUtils.getStoredShoppingCart(checkoutId)) {
            ShoppingCartUtils.deleteStoredShoppingCart(checkoutId);
        }
        CookiesManagement.isAudioAvailable =
            this.planogram.audioSettings && Object.keys(this.planogram.audioSettings).length > 0;
        const audioEnabled = CookiesManagement.isAudioAvailable || this.planogram.items.some(item => { var _a; return ((_a = item.action) === null || _a === void 0 ? void 0 : _a.type) === 'audio'; });
        if (audioEnabled) {
            this.audio = new SphereAudio(this.planogram);
        }
        const productInfoService = new ProductInfoService();
        void productInfoService.getCustomButtonsSettings();
        void productInfoService.getPurchasingFlowSettings();
        this.l10nControl = new L10nButtonComponent();
        this.disposeLoadedSubject = pipe(L10nUtils.languageLoadedSubject, subscribe({
            next: (langCode) => {
                if (!langCode) {
                    return;
                }
                this.handleLanguageChanged();
                ProductInfoService.clearCustomButtonsSettingsCache();
                ProductInfoService.clearProductInfoCache();
            }
        }));
    }
    pageView(title) {
        document.title = title;
        Metrics.updateTitle();
        if (!this.firstLoad) {
            Metrics.onUrlChange();
        }
    }
    pagePlanogram() {
        this.pageView(this.planogram.seoTitle);
    }
    pageItem(item) {
        const seo = this.planogram.itemSEO[item === null || item === void 0 ? void 0 : item.id];
        if (seo) {
            this.pageView(seo.title);
        }
        else {
            this.pagePlanogram();
        }
    }
    pageContentOverlay(link) {
        this.pageView(link);
    }
    pageInfo(data) {
        this.pageView(data.seo_title);
    }
    pageProduct(item) {
        var _a, _b;
        const id = (_b = (_a = item.data) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.id;
        if (!id) {
            this.pagePlanogram();
            return;
        }
        const seo = this.planogram.productSEO[id];
        if (seo) {
            this.pageView(seo.title);
        }
        else {
            this.pagePlanogram();
        }
    }
    executeOrQueue(fn) {
        if (this.isSphereLoaded) {
            fn();
        }
        else {
            this.afterLoadQueue.push(fn);
        }
    }
    addRoutes() {
        Router.on(Router.NAVIGATION_PAGES.PLANOGRAM, Router.withLang(planogram => {
            this.onPlanogramChange(planogram, () => {
                this.overlay.hide();
                CookiesManagement.init();
                this.heatMapService.updatePlanogram(this.planogram.versionId, document.cookie);
                this.pagePlanogram();
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.INFO_OVERLAY, Router.withLang((planogram, overlayType) => {
            this.onPlanogramChange(planogram, () => {
                const item = { planogram: this.planogram, action: { type: overlayType } };
                this.overlay.showItem(item); // TODO: nonsense, split item and overlay logic
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.SEARCH_OVERLAY, Router.withLang(planogram => {
            this.onPlanogramChange(planogram, () => {
                this.cameraControls.clearAnimation();
                CookiesManagement.init();
                this.heatMapService.updatePlanogram(this.planogram.versionId, document.cookie);
                this.search.showSearch();
                this.pagePlanogram(); // TODO: should opening search be an analytics event?
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.IMAGE, Router.withLang((planogram, identifier, _, activate) => {
            this.onPlanogramChange(planogram, () => {
                var _a;
                const item = this.sphereItems.findSphereItemByImageId(identifier);
                const withoutNavigation = activate === 'skip';
                if (item) {
                    if (withoutNavigation) {
                        this.inputHandler.handleAction(item);
                    }
                    else if (((_a = item.action) === null || _a === void 0 ? void 0 : _a.type) === ActionType.SingleImage) {
                        this.executeOrQueue(() => this.inputHandler.animateToImageAfterLoad(item.id.toString(), this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, activate === 'action'));
                    }
                    else {
                        this.executeOrQueue(() => this.inputHandler.animateToItemAfterLoad(item, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, activate === 'action'));
                    }
                    this.pageItem(item);
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.VIDEO, Router.withLang((planogram, identifier, activate) => {
            this.onPlanogramChange(planogram, () => {
                const item = this.sphereItems.findSphereItemByActionIdentifier(identifier);
                if (item) {
                    this.executeOrQueue(() => this.inputHandler.animateToItemAfterLoad(item, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY));
                    this.pageItem(item);
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.TEXT, Router.withLang((planogram, identifier, activate) => {
            this.onPlanogramChange(planogram, () => {
                const item = this.sphereItems.findSphereItemByIdentifier(identifier);
                const withoutNavigation = activate === 'skip';
                if (item) {
                    if (withoutNavigation) {
                        this.inputHandler.handleAction(item);
                    }
                    else {
                        this.executeOrQueue(() => this.inputHandler.animateToItemAfterLoad(item, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, activate === 'action'));
                    }
                    this.pageItem(item);
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.SOCIAL_MEDIA_OVERLAY, Router.withLang((planogram, identifier) => {
            this.onPlanogramChange(planogram, () => {
                const item = this.sphereItems.findSphereItemByActionIdentifier(identifier);
                if (this.isSphereLoaded) {
                    this.overlay.showItem(item);
                }
                else {
                    if (item) {
                        this.pageItem(item);
                    }
                    this.executeOrQueue(() => {
                        var _a;
                        return this.inputHandler.animateToItemAfterLoad(item, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, true, ((_a = item === null || item === void 0 ? void 0 : item.action) === null || _a === void 0 ? void 0 : _a.type) === ActionType.SocialLink);
                    });
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.NAVIGATION_MENU, Router.withLang((planogram, identifier) => {
            this.onPlanogramChange(planogram, () => {
                this.cameraControls.clearAnimation();
                const id = parseInt(identifier, 10);
                const item = this.planogram.planogramVersionControlButtons[id];
                if (item) {
                    this.activeMenu = id;
                    this.showNavigationMenu(item.menu);
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.SHAPE, Router.withLang((planogram, identifier, activate) => {
            this.onPlanogramChange(planogram, () => {
                const item = this.sphereItems.findSphereItemByIdentifier(identifier);
                const withoutNavigation = activate === 'skip';
                if (item) {
                    if (withoutNavigation) {
                        this.inputHandler.handleAction(item);
                    }
                    else {
                        this.executeOrQueue(() => this.inputHandler.animateToItemAfterLoad(item, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, activate === 'action'));
                    }
                    this.pageItem(item);
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.IFRAME, Router.withLang((planogram, identifier) => {
            this.onPlanogramChange(planogram, () => {
                const item = this.sphereItems.findSphereItemByActionIdentifier(identifier);
                if (this.isSphereLoaded) {
                    this.overlay.showItem(item);
                }
                else {
                    if (item) {
                        this.pageItem(item);
                    }
                    this.executeOrQueue(() => {
                        var _a;
                        return this.inputHandler.animateToItemAfterLoad(item, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, true, ((_a = item === null || item === void 0 ? void 0 : item.action) === null || _a === void 0 ? void 0 : _a.type) === ActionType.IframeLink);
                    });
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.VIDEO_OVERLAY, Router.withLang((planogram, videoId) => {
            this.onPlanogramChange(planogram, () => {
                const item = this.sphereItems.findSphereItemByActionIdentifier(videoId);
                if (this.isSphereLoaded) {
                    this.overlay.showItem(item);
                }
                else {
                    if (item) {
                        this.pageItem(item);
                    }
                    this.executeOrQueue(() => this.inputHandler.animateToItemAfterLoad(item, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, true, true));
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.CAROUSEL_OVERLAY, Router.withLang((planogram, identifier) => {
            this.onPlanogramChange(planogram, () => {
                const item = this.sphereItems.findSphereItemByActionIdentifier(identifier);
                if (this.isSphereLoaded) {
                    this.overlay.showItem(item);
                }
                else {
                    if (item) {
                        this.pageItem(item);
                    }
                    this.inputHandler.animateToItemAfterLoad(item, 0, true, true);
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.CLUSTER_AREA, Router.withLang((planogram, clusterName) => {
            this.onPlanogramChange(planogram, () => {
                if (this.overlay.isShowing()) {
                    this.overlay.closeOverlay(false);
                }
                let item = this.sphereItems.findClusterByClusterName(clusterName);
                if (item) {
                    this.executeOrQueue(() => this.inputHandler.animateToClusterAfterLoad(clusterName, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY));
                }
                else {
                    item = this.sphereItems.findSphereItemByIdentifier(clusterName);
                    this.executeOrQueue(() => this.inputHandler.animateToItemAfterLoad(item, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, true));
                }
                this.pageItem(item);
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.ANIMATION_PATH, Router.withLang((planogram, pathIdentifier) => {
            this.onPlanogramChange(planogram, () => {
                const animationPath = this.planogram.animation_paths.find(path => path.name === pathIdentifier || path.uuid === pathIdentifier);
                if ((animationPath === null || animationPath === void 0 ? void 0 : animationPath.uuid) === pathIdentifier) {
                    Router.navigateToPlanogramWithPath(planogram, UrlUtils.slugify(animationPath.name));
                }
                else {
                    CookiesManagement.init({
                        externalAction: () => {
                            this.inputHandler.handleInitAnimationPath(pathIdentifier);
                        },
                        disableEntranceGuide: true
                    });
                }
                if (animationPath === null || animationPath === void 0 ? void 0 : animationPath.seoTitle) {
                    this.pageContentOverlay(animationPath.seoTitle);
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.CONTENT_OVERLAY, Router.withLang((planogram, link) => {
            this.onPlanogramChange(planogram, () => {
                const item = this.sphereItems.findSphereItemByContentLink(link);
                if (this.isSphereLoaded) {
                    this.overlay.showItem(item);
                }
                else if (item) {
                    this.executeOrQueue(() => this.inputHandler.animateToItemAfterLoad(item, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, true, true));
                    this.pageContentOverlay(link);
                }
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.PRODUCT_BY_CODE_AND_NAME, Router.withLang((planogram, identifier, name, type) => {
            this.onPlanogramChange(planogram, () => {
                var _a, _b;
                const withoutNavigation = type === 'skip' ||
                    (this.isSphereLoaded &&
                        (!type ||
                            type === "gallery" /* PRODUCT_PAGE_ROUTES.GALLERY */ ||
                            type === "details" /* PRODUCT_PAGE_ROUTES.DETAILS */ ||
                            type === "checkout" /* PRODUCT_PAGE_ROUTES.CHECKOUT */));
                const itemId = (_a = new URLSearchParams(Router.SEARCH_PARAMS).get('uuid')) !== null && _a !== void 0 ? _a : identifier;
                const item = this.sphereItems.findSphereItemByIdentifier(itemId);
                if (!item) {
                    Router.navigateToPlanogram(planogram);
                    return;
                }
                if (((_b = item === null || item === void 0 ? void 0 : item.action) === null || _b === void 0 ? void 0 : _b.type) === ActionType.ProductOverlay) {
                    if (withoutNavigation)
                        this.inputHandler.setProductOverlay(itemId, type);
                    else
                        this.executeOrQueue(() => this.inputHandler.animateToProductAfterLoad(itemId, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, type));
                }
                else {
                    this.executeOrQueue(() => this.inputHandler.animateToItemAfterLoad(item, this.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY, type === 'action'));
                }
                this.pageProduct(item);
            });
        }));
        Router.on(Router.NAVIGATION_PAGES.PLANOGRAM_FALLBACK, Router.withLang(planogram => {
            Router.navigateToPlanogram(planogram);
        }));
        Router.on(Router.NAVIGATION_PAGES.OTHER, () => {
            Router.navigateToPlanogram(PLANOGRAM_NAME.MAIN);
        });
    }
    setupRenderer() {
        this.canvasRenderer = new CanvasRenderer(this.canvas, this.planogram, this.sphereShape);
        this.canvasRenderer.onViewportChange(viewport => {
            if (this.videoLimiter)
                this.videoLimiter.updateViewport(viewport);
        });
        this.canvasRenderer.onRender(dt => this.onRender(dt));
    }
    onPlanogramChange(planogramName, cb = Function()) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_a = this.planogram) === null || _a === void 0 ? void 0 : _a.name) === planogramName && this.previousLanguage === L10nUtils.getCurrentLanguage()) {
            cb();
            return;
        }
        if (((_b = this.planogram) === null || _b === void 0 ? void 0 : _b.name) !== planogramName) {
            (_c = this.l10nControl) === null || _c === void 0 ? void 0 : _c.clearAvailableLanguagesList();
        }
        loadingService.onLoad(() => {
            sphereEventHandler.emit(EVENTS.SPHERE.INIT, this.planogram);
        });
        loadingService.onReset(() => {
            sphereEventHandler.emit(EVENTS.SPHERE.RESET, this.planogram);
        });
        if (!this.firstLoad) {
            loadingService.reset();
            this.isSphereLoaded = false;
            this.sphereItems.dispose();
            this.cameraControls.clearAnimation();
            (_d = this.canvasRenderer) === null || _d === void 0 ? void 0 : _d.dispose();
            (_e = this.audio) === null || _e === void 0 ? void 0 : _e.dispose();
            (_f = this.navigationArrows) === null || _f === void 0 ? void 0 : _f.dispose();
            this.videos.splice(0, this.videos.length);
        }
        this.previousPlanogram = (_g = this.planogram) === null || _g === void 0 ? void 0 : _g.name;
        this.previousLanguage = L10nUtils.getCurrentLanguage();
        this.planogram = new Planogram(planogramName);
        L10nUtils.setCurrentPlanogram(planogramName);
        document.getElementById('navigation-menu-container').classList.add('is-hidden');
        this.planogram.load().then(() => {
            this.sphereShape = new SphereShape(Planogram.ALPHA, 3, this.planogram.width);
            this.setupRenderer();
            L10nUtils.setPlanogramId(this.planogram.id);
            AppState.clientName = this.planogram.clientName;
            EntranceGuide.settings = this.planogram.entranceAnimation;
            this.afterLoadQueue.push(cb);
            this.initSphere();
            if (this.firstLoad)
                this.initApp();
            this.syncLogoIcon();
            this.syncOverlayAndControlColors();
            this.syncSocialMediaOverlayAndControlColors();
            this.syncControlButtons();
            this.overlay.hide();
            sphereEventHandler.emit(EVENTS.PLANOGRAM_CHANGED, this.planogram);
            this.searchService.updateSearchSettings();
            this.firstLoad = false;
        });
    }
    resetSphere() {
        var _a, _b, _c, _d, _e, _f, _g;
        (_a = this.overlay) === null || _a === void 0 ? void 0 : _a.dispose();
        (_b = this.l10nControl) === null || _b === void 0 ? void 0 : _b.dispose();
        (_c = this.account) === null || _c === void 0 ? void 0 : _c.dispose();
        (_d = this.currencyService) === null || _d === void 0 ? void 0 : _d.dispose();
        (_e = this.currencySwitcherControl) === null || _e === void 0 ? void 0 : _e.dispose();
        (_f = this.shoppingCart) === null || _f === void 0 ? void 0 : _f.dispose();
        (_g = this.shoppingCartService) === null || _g === void 0 ? void 0 : _g.dispose();
        this.disposeLoadedSubject();
        sphereEventHandler.off([
            EVENTS.CAMERA.ZOOM_BY,
            EVENTS.OVERLAY.SHOW_CONTENT,
            EVENTS.ON_MOVEMENT_START,
            EVENTS.ANIMATE_ZOOM_FOV,
            EVENTS.AUTOROTATE,
            EVENTS.VIDEO.PLAY,
            EVENTS.ACCOUNT.OPEN
        ].join(' '), this.resetAutoplayRef);
        sphereEventHandler.off(EVENTS.OVERLAY.CLOSE_CONTENT, this.pagePlanogramRef);
        sphereEventHandler.off(EVENTS.OVERLAY.SHOW_INFO_OVERLAY_CONTENT, this.pageInfoRef);
        sphereEventHandler.off([
            EVENTS.CAMERA.ZOOM_BY,
            EVENTS.TILT_AND_PAN_TO,
            EVENTS.ANIMATE_ZOOM_FOV,
            EVENTS.AUTOROTATE,
            EVENTS.VIDEO.PLAY,
            EVENTS.ACCOUNT.OPEN
        ].join(' '), this.resetNavigationRef);
        sphereEventHandler.off(SPHERE_EVENT_NAMES.SPHERE.BLUR_CANVAS, this.handleBlurCanvas);
        sphereEventHandler.off(SPHERE_EVENT_NAMES.SPHERE.UNBLUR_CANVAS, this.hanldeUnblurCanvas);
        sphereEventHandler.off(SPHERE_EVENT_NAMES.SPHERE.SET_CURSOR, this.setCursor);
        this.cancelLoading();
        this.isSphereLoaded = false;
    }
    initSphere() {
        var _a, _b, _c;
        if (!this.firstLoad) {
            this.resetSphere();
        }
        this.sphereItems = new SphereItems(this.planogram);
        const loadingSteps = [];
        loadingSteps.push(this.canvasRenderer
            .addItems(this.sphereItems)
            .then(() => Promise.all([
            this.canvasRenderer.preloadLod(),
            this.sphereItems.loadSphereItems(this.canvasRenderer.capabilities)
        ])));
        let cancel = false;
        this.cancelLoading = () => {
            cancel = true;
        };
        Promise.all(loadingSteps)
            .then(() => {
            if (cancel) {
                throw new Error('Loading interrupted');
            }
        })
            .then(() => {
            this.videos = this.sphereItems.items.filter(item => item.type === SphereItemType.Video);
            this.videoLimiter = new VideoLimiter(this.planogram, this.videos, BrowserUtils.isMobileSafari() ? 5 : 2, BrowserUtils.isMobileSafari() ? 5 : +Infinity);
            loadingService.progressStage(LOADING_STAGES.INIT_SPHERE, 1);
            // don't use .forEach, need to account for pushing more items to afterLoadQueue
            for (let i = 0; i < this.afterLoadQueue.length; i++) {
                this.afterLoadQueue[i]();
            }
            this.afterLoadQueue = [];
            this.isSphereLoaded = true;
            if (this.overlay.isShowing()) {
                this.handleBlurCanvas();
            }
            this.canvasRenderer.start();
        });
        this.initializeServices();
        this.cameraControls = new CameraControls(this.canvasRenderer.camera, this.sphereShape, this.sphereItems, this.planogram);
        this.overlay = new Overlay(this.planogram, this.currencyService, this.shoppingCartService);
        this.inputHandler = new InputHandler(this.canvasRenderer, this.overlay, this.planogram, this.cameraControls, this.sphereItems);
        this.overlay.setRedirectToProduct(this.inputHandler.redirectToProduct.bind(this.inputHandler));
        if (!this.firstLoad) {
            this.mouseControls.dispose();
            this.keyboardControls.dispose();
            this.touchControls.dispose();
        }
        const domElement = document.getElementById('main-container');
        this.mouseControls = new MouseControls(domElement, this.cameraControls, this.inputHandler);
        this.keyboardControls = new KeyboardControls(this.cameraControls, this.inputHandler, this.overlay);
        this.touchControls = new TouchControls(domElement, this.cameraControls, this.inputHandler);
        const navigationArrowsEnabled = (((_a = this.planogram.navigationArrow) === null || _a === void 0 ? void 0 : _a.enabled) && !((_b = this.planogram.navigationArrow) === null || _b === void 0 ? void 0 : _b.touch_devices_only)) ||
            (((_c = this.planogram.navigationArrow) === null || _c === void 0 ? void 0 : _c.touch_devices_only) && isTouchSupported);
        if (navigationArrowsEnabled) {
            this.navigationArrows = new NavigationArrows(this.planogram, this.cameraControls, this.inputHandler);
        }
        sphereEventHandler.listen([
            EVENTS.CAMERA.ZOOM_BY,
            EVENTS.OVERLAY.SHOW_CONTENT,
            EVENTS.ON_MOVEMENT_START,
            EVENTS.ANIMATE_ZOOM_FOV,
            EVENTS.AUTOROTATE,
            EVENTS.VIDEO.PLAY,
            EVENTS.ACCOUNT.OPEN
        ].join(' '), this.resetAutoplayRef);
        sphereEventHandler.listen(EVENTS.OVERLAY.CLOSE_CONTENT, this.pagePlanogramRef);
        sphereEventHandler.listen(EVENTS.OVERLAY.SHOW_INFO_OVERLAY_CONTENT, this.pageInfoRef);
        sphereEventHandler.listen([
            EVENTS.CAMERA.ZOOM_BY,
            EVENTS.TILT_AND_PAN_TO,
            EVENTS.ANIMATE_ZOOM_FOV,
            EVENTS.AUTOROTATE,
            EVENTS.VIDEO.PLAY,
            EVENTS.ACCOUNT.OPEN
        ].join(' '), this.resetNavigationRef);
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.COOKIES.ALLOW_ZOOM_CONTROLS, this.handleZoomButtonClickRef);
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.SPHERE.BLUR_CANVAS, this.handleBlurCanvas);
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.SPHERE.UNBLUR_CANVAS, this.hanldeUnblurCanvas);
        sphereEventHandler.listen(SPHERE_EVENT_NAMES.SPHERE.SET_CURSOR, this.setCursor);
        EntranceGuide.planogramName = this.planogram.name;
        EntranceGuide.clientName = this.planogram.clientName;
        sphereEventHandler.emit(EVENTS.SPHERE.INIT, this.planogram);
        FontLoader.init(this.planogram.id);
    }
    storeCredentialCookies() {
        const login = UrlUtils.getQueryValueFromUrl('login');
        const password = UrlUtils.getQueryValueFromUrl('password');
        if (login && password) {
            Cookies.set(`sphere-credentials-${this.planogram.name}`, `${login}:${password}`, {
                expires: 7,
                sameSite: "Lax" /* COOKIES_ATTRIBUTES.SAME_SITE_LAX */
            });
        }
    }
    handleBlurCanvas(event = { kernelSize: 3 }) {
        var _a;
        this.canvasRenderer.blur((_a = event.kernelSize) !== null && _a !== void 0 ? _a : this.planogram.blurScale);
    }
    hanldeUnblurCanvas() {
        this.canvasRenderer.blur();
    }
    // TODO: consider moving this and the other picking stuff to Sphere (since it
    // is conceptually only on the Sphere, rather than the entire application).
    setCursor(event) {
        const point = { x: event.clientX, y: event.clientY };
        const { mesh } = this.canvasRenderer.getInteractableObjectAtScreenCoordinate(point.x, point.y);
        const item = mesh ? mesh.userData.component : undefined;
        if (item === undefined) {
            this.canvas.classList.remove('has-cursor-pointer');
            if (this.hoveredItem !== undefined) {
                this.hoveredItem.onHoverLeave();
            }
        }
        else {
            if (this.hoveredItem === undefined) {
                this.canvas.classList.add('has-cursor-pointer');
                item.onHoverEnter();
            }
            else if (item !== this.hoveredItem) {
                this.canvas.classList.add('has-cursor-pointer');
                item.onHoverEnter();
                this.hoveredItem.onHoverLeave();
            }
        }
        this.hoveredItem = item;
    }
    getPlanogramCoordinates(x, y) {
        const cameraPosition = this.canvasRenderer.camera.position;
        const normalizedCoords = normalizeMouse(x, y);
        this.cameraDirection
            .set(normalizedCoords.x, normalizedCoords.y, 0.5)
            .unproject(this.canvasRenderer.camera.perspectiveCamera)
            .sub(cameraPosition)
            .normalize();
        const point = this.sphereShape.castRayFarthestPoint(new Ray(cameraPosition, this.cameraDirection), new Vector3());
        const coords = this.sphereShape.planogramCoordinateViewer(this.sphereShape.reverse(point), this.planogram.size());
        return invertYAxis(coords, this.planogram.size().y);
    }
    resizeCanvas() {
        if (!this.canvasRenderer)
            return;
        this.canvasRenderer.resize();
        this.heatMapService.updateScreenSize(window.screen.width, window.screen.height);
        if (this.overlay.isShowing()) {
            this.overlay.resize();
        }
        if (Search.isSearchActive) {
            this.search.updateSearchPos();
        }
    }
    setupForegroundElements() {
        this.fullScreen = new Fullscreen(document.getElementById('fullscreen-button-list'), document.body, document, document.getElementById('main-container'), this.resizeCanvas.bind(this));
    }
    resetAutoplay() {
        this.inputHandler.resetAutoplay();
    }
    resetNavigation() {
        this.inputHandler.resetNavigation();
    }
    syncControlButtons() {
        const container = document.querySelector('#bottom-container .navigation-buttons-wrapper');
        container.classList.remove(...Object.values(ALIGNMENT_TYPES));
        HTMLUtils.removeChildElements(container);
        container.classList.add(this.applyButtonAlignment());
        this.planogram.planogramVersionControlButtons.forEach((button, index) => {
            var _a;
            const element = document.createElement('div');
            const elementIconWrapper = document.createElement('div');
            const elementIcon = document.createElement('img');
            elementIconWrapper.classList.add('navigation-button-icon-wrapper');
            element.classList.add('navigation-button', 'button');
            const controlButtonUrl = (_a = button === null || button === void 0 ? void 0 : button.control_button) === null || _a === void 0 ? void 0 : _a.url;
            if (button.show_shadow) {
                element.classList.add('with-shadow');
            }
            if (button.show_title && button.title) {
                const title = document.createElement('span');
                title.innerText = button.title;
                element.append(title);
                const fontSettings = Object.assign(Object.assign({}, button.planogram_version_control_button_font), { assignment: `navigation-button-${index + 1}` });
                FontLoader.mountCustomFont(fontSettings);
            }
            if (!this.planogram) {
                return;
            }
            if (!button || button.enabled === false || (button.navigation_type === 'back' && this.firstLoad)) {
                element.classList.add('is-hidden');
                container.appendChild(element);
                return;
            }
            element.classList.remove('is-hidden');
            elementIcon.setAttribute('src', controlButtonUrl);
            elementIcon.onerror = () => {
                elementIcon.setAttribute('src', URLS.CONTROL_BUTTON_ICON_FALLBACK);
            };
            elementIconWrapper.append(elementIcon);
            element.append(elementIconWrapper);
            element.addEventListener('click', () => {
                var _a;
                Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.CLICK_CONTROL_BUTTON(UrlUtils.slugify((_a = button.title) !== null && _a !== void 0 ? _a : '')));
                switch (true) {
                    case button.element_type === NAVIGATION_BUTTON_TYPE.SEARCH: {
                        searchEventHandler.emit(SEARCH_EVENTS.SHOW_SEARCH, { isSearchActive: true });
                        break;
                    }
                    case button.element_type === NAVIGATION_BUTTON_TYPE.MENU: {
                        if (!button.menu)
                            return;
                        if (this.activeMenu === index) {
                            this.activeMenu = undefined;
                            document.getElementById('navigation-menu-container').classList.add('is-hidden');
                            Router.navigateToPlanogram(this.planogram.name);
                            return;
                        }
                        Router.navigateToNavigationMenu(this.planogram.name, index.toString());
                        break;
                    }
                    default: {
                        this.activeMenu = undefined;
                        document.getElementById('navigation-menu-container').classList.add('is-hidden');
                        this.handleControlButtonClick(button);
                    }
                }
            });
            container.appendChild(element);
        });
    }
    showNavigationMenu(menu) {
        const items = menu.menu_items;
        const pattern = [2, 3, 2, 3, 2];
        const container = document.getElementById('navigation-menu-container');
        container.classList.remove('is-hidden');
        container.addEventListener('click', () => {
            container.classList.add('is-hidden');
            this.activeMenu = undefined;
            Router.navigateToPlanogram(this.planogram.name);
        });
        HTMLUtils.removeChildElements(container);
        const innerContainer = document.createElement('div');
        innerContainer.className = 'inner-nav-menu-container';
        container.appendChild(innerContainer);
        let itemIndex = 0;
        while (itemIndex < items.length) {
            pattern.forEach(count => {
                const row = document.createElement('div');
                row.className = 'navigation-menu-row';
                innerContainer.appendChild(row);
                for (let i = 0; i < count; i++) {
                    if (itemIndex >= items.length) {
                        return;
                    }
                    const item = document.createElement('div');
                    item.className = 'navigation-menu-item';
                    this.appendItemContent(item, items[itemIndex], menu.show_shadow, itemIndex);
                    itemIndex++;
                    row.appendChild(item);
                }
            });
        }
    }
    updateNavigationMenuVisibility() {
        var _a;
        const show = Router.NAVIGATION_PAGES.NAVIGATION_MENU.test(window.location.pathname);
        const container = document.getElementById('navigation-menu-container');
        SphereApp.isNavigationMenuActive = show;
        if (show) {
            container.classList.remove('is-hidden');
            (_a = this.inputHandler) === null || _a === void 0 ? void 0 : _a.hideOverlay();
        }
        else {
            this.activeMenu = undefined;
            container.classList.add('is-hidden');
        }
        return show;
    }
    appendItemContent(element, item, showShadow, index) {
        const itemIcon = document.createElement('div');
        itemIcon.className = 'navigation-menu-item-icon';
        const image = document.createElement('img');
        image.src = item.control_button.url;
        image.onclick = e => {
            e.stopPropagation();
            Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.CLICK_MENU_BUTTON(item.title));
            this.handleMenuButtonClick(item);
        };
        if (showShadow) {
            image.classList.add('with-shadow');
        }
        itemIcon.appendChild(image);
        element.appendChild(itemIcon);
        if (item.show_title && item.title) {
            const title = document.createElement('span');
            title.classList.add('navigation-menu-item-text');
            title.classList.add(`navigation-menu-item-text-${index + 1}`);
            const span = document.createElement('span');
            span.innerText = item.title;
            title.appendChild(span);
            element.append(title);
            const fontSettings = Object.assign(Object.assign({}, item.menu_item_font), { assignment: `navigation-menu-item-text-${index + 1}` });
            FontLoader.mountCustomFont(fontSettings);
            element.appendChild(title);
        }
    }
    applyButtonAlignment() {
        let classNames = '';
        switch (true) {
            case this.planogram.navigationDistributeEvenly: {
                classNames = ALIGNMENT_TYPES.DISTRIBUTE;
                break;
            }
            case this.planogram.navigationAlignment === ALIGNMENT_TYPES.LEFT: {
                classNames = ALIGNMENT_TYPES.LEFT;
                break;
            }
            case this.planogram.navigationAlignment === ALIGNMENT_TYPES.RIGHT: {
                classNames = ALIGNMENT_TYPES.RIGHT;
                break;
            }
            case this.planogram.navigationAlignment === ALIGNMENT_TYPES.MIDDLE: {
                classNames = ALIGNMENT_TYPES.MIDDLE;
                break;
            }
        }
        return classNames;
    }
    handleInternalNavigation(item) {
        const [planogram, objectType, identifier, actionType] = item.navigation_value.split('/');
        const underscoreIndex = planogram.lastIndexOf('_');
        const planogramName = planogram.substring(0, underscoreIndex);
        const langCode = planogram.substring(underscoreIndex + 1);
        const typeMap = {
            video: SphereItemType.Video,
            text_area: SphereItemType.Text,
            cluster: SphereItemType.Cluster,
            shape: SphereItemType.Shape,
            line: SphereItemType.Curve, // verify what kind of URL works for curves
            picture: SphereItemType.Image,
            product: SphereItemType.Product
        };
        let action = '/action';
        if (actionType === 'object' && !item.without_navigation) {
            action = '/show';
        }
        else if (actionType === 'action' && item.without_navigation) {
            action = '/skip';
        }
        let url;
        if (typeMap[objectType]) {
            const [itemId, uuid] = AppUtils.getSubstringId(identifier, objectType);
            const queryUuid = uuid ? `?uuid=${uuid}` : '';
            url = Router.generateItemUrl(itemId, typeMap[objectType], planogramName) + action + queryUuid;
        }
        else if (objectType === 'path') {
            url = Router.generateAnimationPathUrl(UrlUtils.slugify(identifier), planogramName);
        }
        else {
            url = `/${planogramName}`;
        }
        if (item.open_in_new_page) {
            WebUtils.openLink(`${location.origin}/${langCode}${url}`);
        }
        else {
            Router.navigate(url, { langCode });
        }
    }
    handleMenuButtonClick(menuItem) {
        const closeOnClick = !menuItem.open_in_new_page;
        if (menuItem.navigation_type === 'internal') {
            this.handleInternalNavigation(menuItem);
        }
        else if (menuItem.navigation_type === 'external') {
            if (menuItem.open_in_new_page) {
                WebUtils.openLink(menuItem.navigation_value);
            }
            else {
                WebUtils.redirectToSpecificUrl(menuItem.navigation_value);
            }
        }
        if (closeOnClick) {
            this.activeMenu = undefined;
            document.getElementById('navigation-menu-container').classList.add('is-hidden');
        }
    }
    handleControlButtonClick(controlButton) {
        var _a;
        if (!this.isSphereLoaded) {
            return;
        }
        this.resetNavigation();
        this.cameraControls.clearAnimation();
        if ((controlButton === null || controlButton === void 0 ? void 0 : controlButton.navigation_type) === 'back' && this.previousPlanogram) {
            Router.navigateToPlanogram(this.previousPlanogram);
            return;
        }
        if ((controlButton === null || controlButton === void 0 ? void 0 : controlButton.navigation_type) === 'internal') {
            if (!(controlButton === null || controlButton === void 0 ? void 0 : controlButton.open_in_new_page)) {
                this.previousPlanogram = this.planogram.name;
            }
            this.handleInternalNavigation(controlButton);
            return;
        }
        if ((controlButton === null || controlButton === void 0 ? void 0 : controlButton.navigation_type) === 'external') {
            // TODO: parse with a single regex match
            const ITEM_IDENTIFIER_URL_REGEX = /\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/(i|y|t|s|f|p|v|c|o|sm)([\w-]+)/;
            const formattedUrl = controlButton.navigation_value && controlButton.navigation_value.match(ITEM_IDENTIFIER_URL_REGEX);
            const lang = L10nUtils.getCurrentLanguage();
            const isInternalUrl = (formattedUrl === null || formattedUrl === void 0 ? void 0 : formattedUrl[0].includes(this.planogram.name)) && (formattedUrl === null || formattedUrl === void 0 ? void 0 : formattedUrl[1]) === lang;
            if (isInternalUrl) {
                const parsedUrl = `/${controlButton.navigation_value.slice(controlButton.navigation_value.lastIndexOf(this.planogram.name))}`;
                const identifier = (_a = parsedUrl.match(ITEM_IDENTIFIER_URL_REGEX)) === null || _a === void 0 ? void 0 : _a[4];
                const animate = new URL(controlButton.navigation_value).hash;
                if (animate && identifier) {
                    Router.navigateToItemOnSphere(`/${this.planogram.name}/s${identifier}`, true);
                }
                else {
                    Router.navigateToItemOnSphere(parsedUrl);
                }
            }
            else {
                if (controlButton.open_in_new_page) {
                    WebUtils.openLink(controlButton.navigation_value);
                }
                else {
                    WebUtils.redirectToSpecificUrl(controlButton.navigation_value);
                }
            }
            return;
        }
    }
    handleZoomButtonClick() {
        if (!isTouchSupported) {
            return;
        }
        const container = document.getElementById('middle-right-container');
        const zoomButtonsContainer = document.createElement('div');
        const zoomInButton = document.createElement('div');
        const zoomOutButton = document.createElement('div');
        zoomInButton.classList.add('zoom-button', 'zoom-in');
        zoomOutButton.classList.add('zoom-button', 'zoom-out');
        zoomButtonsContainer.id = 'zoom-buttons-container';
        zoomButtonsContainer.appendChild(zoomInButton);
        zoomButtonsContainer.appendChild(zoomOutButton);
        container.appendChild(zoomButtonsContainer);
        container.classList.remove('is-hidden');
        InputEventUtils.addSelectEvents(zoomInButton, this.handleZoom.bind(this, HEATMAP_EVENT_TYPES.ZOOM_IN));
        InputEventUtils.addSelectEvents(zoomOutButton, this.handleZoom.bind(this, HEATMAP_EVENT_TYPES.ZOOM_OUT));
    }
    handleZoom(direction) {
        const zoomFactor = 0.725;
        const startPoint = { x: window.innerWidth * 0.5, y: window.innerHeight * 0.5 };
        this.cameraControls.zoomToPoint(startPoint, direction === HEATMAP_EVENT_TYPES.ZOOM_IN ? zoomFactor : 1 / zoomFactor);
    }
    syncLogoIcon() {
        var _a, _b;
        const sphereLogoContainer = document.getElementById('sphere-logo');
        if (!this.planogram || !sphereLogoContainer) {
            return;
        }
        sphereLogoContainer.classList.add('app-logo-disabled');
        if (!this.planogram.planogramVersionLogo || this.planogram.planogramVersionLogo.enabled === false) {
            return;
        }
        const logoImgElement = sphereLogoContainer.querySelector('img');
        const logoUrl = (_b = (_a = this.planogram.planogramVersionLogo) === null || _a === void 0 ? void 0 : _a.logo) === null || _b === void 0 ? void 0 : _b.url;
        if (!logoUrl) {
            if (this.defaultLogoUrl) {
                logoImgElement.onload = () => {
                    sphereLogoContainer.classList.remove('app-logo-disabled');
                };
                logoImgElement.setAttribute('src', this.defaultLogoUrl);
            }
            return;
        }
        const previousLogoUrl = logoImgElement.getAttribute('src');
        if (!this.defaultLogoUrl) {
            this.defaultLogoUrl = previousLogoUrl;
        }
        logoImgElement.onload = () => {
            sphereLogoContainer.classList.remove('app-logo-disabled');
        };
        logoImgElement.onerror = () => {
            logoImgElement.setAttribute('src', previousLogoUrl);
            sphereLogoContainer.classList.remove('app-logo-disabled');
        };
        logoImgElement.setAttribute('src', logoUrl);
    }
    syncOverlayAndControlColors() {
        const root = document.documentElement;
        const primaryColorRgb = AppUtils.hex2rgb(this.planogram.primaryColor);
        const secondaryColorRgb = AppUtils.hex2rgb(this.planogram.secondaryColor);
        const overlayBackdropRgb = AppUtils.hex2rgb(this.planogram.overlayBackdropColor);
        root.style.setProperty(`--overlay-primary-color`, `${this.planogram.primaryColor}`);
        root.style.setProperty(`--overlay-secondary-color`, `${this.planogram.secondaryColor}`);
        root.style.setProperty(`--overlay-primary-color-rgb`, `${primaryColorRgb}`);
        root.style.setProperty(`--overlay-secondary-color-rgb`, `${secondaryColorRgb}`);
        root.style.setProperty(`--iframe-primary-color`, `${this.planogram.iframePrimaryColor}`);
        if (this.planogram.iframeSecondaryColor) {
            root.style.setProperty(`--iframe-secondary-color`, `${this.planogram.iframeSecondaryColor}`);
        }
        if (this.planogram.backgroundColor) {
            root.style.setProperty('--overlay-backdrop-color', `rgba(${overlayBackdropRgb}, ${this.planogram.overlayBackdropOpacity})`);
        }
    }
    syncSocialMediaOverlayAndControlColors() {
        var _a;
        const root = document.documentElement;
        (_a = this.planogram.clientSocialMedias) === null || _a === void 0 ? void 0 : _a.forEach(media => {
            const mediaName = media.social_media_title.toLowerCase();
            if (media.close_bg_color) {
                root.style.setProperty(`--social-media-${mediaName}-close-bg-color`, media.close_bg_color);
            }
            if (media.background_color) {
                const backdropColorRgb = AppUtils.hex2rgb(media.background_color);
                root.style.setProperty(`--social-media-${mediaName}-backdrop-color`, `rgba(${backdropColorRgb}, ${media.opacity})`);
            }
            media.hide_overlay_container
                ? root.style.setProperty(`--social-media-${mediaName}-bg-color`, 'transparent')
                : root.style.setProperty(`--social-media-${mediaName}-bg-color`, media.container_color);
            media.scroll_container_fill
                ? root.style.setProperty(`--social-media-${mediaName}-scroll-container-color`, media.scroll_container_fill)
                : root.style.setProperty(`--social-media-${mediaName}-scroll-container-color`, 'transparent');
            root.style.setProperty(`--social-media-${mediaName}-scroll-indicator-color`, media.scroll_indicator_fill);
            root.style.setProperty(`--social-media-${mediaName}-close-icon-color`, media.close_icon_color);
        });
    }
    handleLogoClick() {
        if (!this.planogram.planogramVersionLogo || !this.planogram.planogramVersionLogo.enabled) {
            Router.navigateToPlanogram(PLANOGRAM_NAME.MAIN);
            return;
        }
        WebUtils.applyConfiguredNavigation(this.planogram.planogramVersionLogo);
    }
    handleLanguageChanged() {
        const sphereLogoIconElement = document.getElementById('sphere-logo').querySelector('img');
        if (sphereLogoIconElement) {
            sphereLogoIconElement.setAttribute('alt', L10nUtils.l10n('logo-button.alt-text'));
        }
    }
    initCurrencySwitcher() {
        const { ecommercePlatformCurrency, ecommercePlatformCurrencies } = this.planogram;
        this.currencyService = new CurrencyService(ecommercePlatformCurrencies, ecommercePlatformCurrency);
        this.currencySwitcherControl = new CurrencySwitcher(this.currencyService);
    }
    initShoppingCart() {
        var _a, _b;
        (_a = this.shoppingCart) === null || _a === void 0 ? void 0 : _a.button.remove();
        (_b = this.shoppingCart) === null || _b === void 0 ? void 0 : _b.panel.remove();
        this.shoppingCartService = AppUtils.isIdealEcommercePlatform(this.planogram) ?
            new IdealCartService(this.planogram, this.currencyService) :
            new ShoppingCartService(this.planogram, this.currencyService);
        this.shoppingCartService.checkMultipassToken().then(data => {
            const buttonSettings = AppUtils.isProfileAndCartIconsEnabled(this.planogram.ecommerceOverlaySettingsButtons.ecommerce_icon_buttons);
            this.shoppingCartService.storeEmail(data === null || data === void 0 ? void 0 : data.email);
            if (buttonSettings[ECOMMERCE_BUTTON_TYPE.USER_PROFILE]) {
                this.account = new Account(this.planogram, this.shoppingCartService);
            }
            if (buttonSettings[ECOMMERCE_BUTTON_TYPE.SHOPPING_CART]) {
                this.shoppingCart = new ShoppingCartComponent(this.planogram, this.shoppingCartService, this.currencyService, this.inputHandler.redirectToProduct.bind(this.inputHandler));
                document.getElementById('shopping-cart').append(this.shoppingCart.button);
            }
            this.shoppingCartService.updateShoppingCartState(true);
        });
    }
}
SphereApp.isNavigationMenuActive = false;
