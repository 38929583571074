import { UrlUtils } from './api/urls';
import { CookiesManagement } from './cookies_management';
import { searchEventHandler, sphereEventHandler } from './custom_event_utils';
import { SEARCH_EVENT_NAMES as SEARCH_EVENTS, SPHERE_EVENT_NAMES as EVENTS } from './event-names';
import { MATOMO_EVENT_NAMES } from './metric-events';
import { Metrics } from './metrics';
import Router from './router';
import { ANIMATION_PATH_KEY, CLUSTER_CAPTION_REGEX, CLUSTER_NAME_REGEX, DIRECTION } from './shared/constants';
import { ActionType, AnimationPathType, isAnimateAction, isAudioAction, isCarouselOverlay, isContentOverlayAction, isInfoOverlayAction, isLinkAction, isProductOverlayAction, isSingleImageAction, isSocialLinkAction, isVideoOverlayAction, SphereItemType } from 'shared/interfaces/planogram';
import { WebUtils } from './utils/web_utils';
import { L10nUtils } from './utils/l10n_utils';
import { AppUtils } from './utils/app_utils';
const MIN_AUTOPLAY_DURATION = 500;
export class InputHandler {
    constructor(canvasRenderer, overlay, planogram, cameraControls, sphereItems) {
        this.canvasRenderer = canvasRenderer;
        this.overlay = overlay;
        this.planogram = planogram;
        this.cameraControls = cameraControls;
        this.sphereItems = sphereItems;
        this.autoplayStarted = false;
    }
    setProductOverlay(productIdentifier, overlayType = "gallery" /* PRODUCT_PAGE_ROUTES.GALLERY */) {
        const item = this.sphereItems.findSphereItemByIdentifier(productIdentifier);
        if (item && !this.isAnimatingToProduct) {
            this.overlay.showItem(item, overlayType);
        }
    }
    navigateToAutoplayClusters() {
        if (this.autoplayStarted) {
            this.autoplaySpeedTime = Math.max(this.autoplaySpeedTime - 1000, MIN_AUTOPLAY_DURATION);
        }
        else if (this.selectedCluster) {
            this.startAutoplay();
        }
        else if (this.planogram.clustersOrder && this.planogram.clustersOrder.length) {
            const clusterId = AppUtils.extractClusterName(this.planogram.clustersOrder[0]);
            Router.navigateToCluster(this.planogram.name, clusterId, { autoplay: true });
        }
    }
    autoplay() {
        this.repeatCallback = () => (this.autoplayTimeoutId = window.setTimeout(this.autoplay.bind(this), this.autoplaySpeedTime));
        if (this.autoplayTimeoutId && this.autoplayStarted) {
            this.cycleThroughClusters(DIRECTION.RIGHT, true);
        }
        else {
            const clusterName = this.selectedCluster ? AppUtils.extractClusterName(`cluster-${this.selectedCluster}`) : '';
            Router.navigateToCluster(this.planogram.name, clusterName, { autoplay: true });
        }
    }
    handleClick(x, y) {
        var _a;
        let isCookieOpened = false;
        try {
            isCookieOpened = CookiesManagement.cookiePopup.isOpen();
        }
        catch (e) {
            console.error('--- Can not handle response from Cookies popup');
        }
        if (this.overlay.isShowing() || isCookieOpened) {
            return;
        }
        const { mesh, point } = this.canvasRenderer.getInteractableObjectAtScreenCoordinate(x, y);
        const item = mesh ? (_a = mesh.userData) === null || _a === void 0 ? void 0 : _a.component : undefined;
        sphereEventHandler.emit(EVENTS.CONTROL.CLICK_ITEM, { item });
        sphereEventHandler.emit(EVENTS.HEATMAP.CLICK, {
            x,
            y,
            zoomLevel: this.canvasRenderer.camera.currentZoomFraction(),
            item
        });
        if (item === undefined) {
            return;
        }
        item.onClick(point);
        if (item.action) {
            this.handleAction(item);
        }
    }
    handleAnimateAction(data, planogramName, activate, redirect = false) {
        switch (data.itemType) {
            case SphereItemType.Cluster:
                Router.navigateToCluster(planogramName, AppUtils.extractClusterName(data.clusterLink));
                break;
            case SphereItemType.Product:
                if (!redirect) {
                    this.animateCameraToIdentifer(data.productIdentifier, activate);
                }
                else {
                    if (data.productName) {
                        Router.navigateToProductIdAndName(data.productIdentifier, data.productName, planogramName, !activate && "show" /* PRODUCT_PAGE_ROUTES.SHOW */);
                    }
                    else {
                        Router.navigateToProductId(data.productIdentifier, planogramName, !activate && "show" /* PRODUCT_PAGE_ROUTES.SHOW */);
                    }
                }
                break;
            case SphereItemType.AnimationPath:
                if (data.sphereName) {
                    const [sphereName, language] = data.sphereName.split('_');
                    Router.navigateToPlanogramWithPath(sphereName, data.itemId, { langCode: language });
                }
                else {
                    Router.navigateToPlanogramWithPath(planogramName, data.itemId);
                }
                break;
            case SphereItemType.Image:
            case SphereItemType.Video:
            case SphereItemType.Text:
            case SphereItemType.TextArea:
            case SphereItemType.Shape:
            case SphereItemType.Curve:
                Router.navigateToItem(planogramName, data.itemId, data.itemType);
                break;
            default:
                Router.navigateToPlanogram(planogramName);
        }
    }
    handleAction(item) {
        var _a, _b;
        if (item.action === undefined)
            return;
        if (isAnimateAction(item.action)) {
            const data = item.action.data;
            if (data.sphereName) {
                const language = data.sphereName.split('_').pop();
                data.sphereName = data.sphereName.replace(`_${language}`, '');
                L10nUtils.selectLanguage(language).then(() => this.handleAnimateAction(data, data.sphereName, data.applyItemsActionInTheEnd, true));
                CookiesManagement.init();
                this.overlay.hide();
            }
            else {
                this.handleAnimateAction(data, this.planogram.name, data.applyItemsActionInTheEnd);
            }
            Metrics.storeTheEvent(this.planogram.name, 'click', `${MATOMO_EVENT_NAMES.WEBGL_CLICK_ANIMATE}_${item.type.toLowerCase()}${WebUtils.getItemName(item, '-')}`);
            return;
        }
        if (isLinkAction(item.action) && item.action.type === ActionType.ExternalLink) {
            WebUtils.openLink(item.action.data.url);
            Metrics.storeTheEvent(this.planogram.name, 'click', `${MATOMO_EVENT_NAMES.WEBGL_CLICK_LINK}-${item.action.data.url}${WebUtils.getItemName(item)}`);
            return;
        }
        if (isProductOverlayAction(item.action)) {
            const product = this.sphereItems.findSphereItemByIdentifier(item.action.data.productIdentifier);
            Router.navigateToProduct(product, "gallery" /* PRODUCT_PAGE_ROUTES.GALLERY */);
            return;
        }
        if (isAudioAction(item.action)) {
            const url = item.action.data.url;
            sphereEventHandler.emit(EVENTS.AUDIO.PLAY_ACTION, { url });
            return;
        }
        if (isVideoOverlayAction(item.action)) {
            Router.navigateToVideoOverlay(item);
            return;
        }
        if (isLinkAction(item.action) && item.action.type === ActionType.IframeLink) {
            Router.navigateToIframe(item);
            return;
        }
        if (isSocialLinkAction(item.action)) {
            Router.navigateToSocialMedia(item);
            return;
        }
        if (isContentOverlayAction(item.action)) {
            Router.navigateToContentOverlay(item.action.data, item.planogram.name);
            return;
        }
        if (isInfoOverlayAction(item.action)) {
            Router.navigateToInfoOverlay(this.planogram.name, item.action.type, true);
            this.overlay.showItem(item);
            return;
        }
        if (isSingleImageAction(item.action)) {
            const imageData = item.data; // TODO: single image action type
            Router.navigateToImage(((_a = imageData.picture) === null || _a === void 0 ? void 0 : _a.id) || imageData.id, (((_b = imageData.picture) === null || _b === void 0 ? void 0 : _b.name) || imageData.image_name || '').split('.')[0].replace(/\W/g, ''), this.planogram.name, true, { silent: true, replace: true });
            this.overlay.showItem(item);
            return;
        }
        if (isCarouselOverlay(item.action)) {
            Router.navigateToCarouselOverlay(item, this.planogram.name);
            return;
        }
        this.overlay.showItem(item);
    }
    animateCameraToCluster(clusterIdentification, delay = 0, upArrow = false, callback) {
        if (!(this.planogram.clustersOrder && this.planogram.clustersOrder.length)) {
            return;
        }
        if (!callback && this.repeatCallback) {
            callback = this.repeatCallback;
        }
        if (!clusterIdentification) {
            this.selectedCluster = clusterIdentification = this.planogram.clustersOrder[0];
        }
        this.cameraControls.clearAnimation();
        let clusterName, animation;
        if (clusterIdentification.match(CLUSTER_CAPTION_REGEX)) {
            clusterName = AppUtils.extractClusterFullName(clusterIdentification);
        }
        else if (clusterIdentification.match(CLUSTER_NAME_REGEX)) {
            clusterName = AppUtils.extractClusterName(clusterIdentification);
        }
        else {
            clusterName = clusterIdentification;
        }
        const item = this.sphereItems.findClusterByClusterName(clusterName);
        if (item) {
            if (this.selectedCluster === clusterIdentification && this.isClusterSelected && !upArrow) {
                animation = this.cameraControls.animateZoomFov();
                this.isClusterSelected = false;
            }
            else {
                animation = this.cameraControls.animateTo(item, callback, { delay });
                this.isClusterSelected = true;
            }
            this.selectedCluster = clusterIdentification;
            if (callback && animation) {
                this.autoplayAnimation = animation;
            }
            else {
                this.resetAutoplay();
            }
        }
        else {
            console.error('There are no cluster with this name');
        }
    }
    animateToClusterAfterLoad(clusterName, delay = 0) {
        searchEventHandler.emit(SEARCH_EVENTS.CLOSE_SEARCH);
        const afterAnimationEnd = () => {
            CookiesManagement.isRedirectAnimationProcessing = false;
            CookiesManagement.init();
        };
        const isAutoplay = UrlUtils.getQueryValueFromUrl('autoplay') === 'true';
        if (isAutoplay && !this.autoplayStarted) {
            this.selectedCluster = clusterName;
            this.startAutoplay();
        }
        if (!isAutoplay) {
            this.resetAutoplay();
        }
        CookiesManagement.isRedirectAnimationProcessing = true;
        this.animateCameraToCluster(clusterName, delay, true, afterAnimationEnd);
        if (isAutoplay && this.repeatCallback) {
            this.repeatCallback();
        }
    }
    animateCameraToItem(identifier, callback, options) {
        const item = this.sphereItems.findSphereItemByIdentifier(identifier);
        if (item) {
            this.cameraControls.clearAnimation();
            this.cameraControls.animateTo(item, callback, options);
        }
        else {
            console.error('There is no item with this identifier');
        }
    }
    handleInitAnimationPath(pathIdentifier, arrowNavigation = false) {
        var _a;
        const animationPathItem = sessionStorage.getItem(ANIMATION_PATH_KEY);
        const animationPathIndex = new URLSearchParams(window.location.search).get('step');
        const animationPath = animationPathItem && !arrowNavigation
            ? JSON.parse(animationPathItem)
            : this.planogram.animation_paths.find(path => path.name === pathIdentifier);
        if (!animationPath || (arrowNavigation && ((_a = this.animationPath) === null || _a === void 0 ? void 0 : _a.name) === pathIdentifier)) {
            return;
        }
        this.animationPath = animationPath;
        this.journeyItems = this.animationPath.items.map(journeyItem => this.sphereItems.findSphereItemByIdentifier(journeyItem.itemId));
        sessionStorage.removeItem(ANIMATION_PATH_KEY);
        this.currentAnimationIndex = animationPathIndex !== null ? parseInt(animationPathIndex) - 1 : undefined;
        if (!arrowNavigation)
            this.handleNextAnimation(false, this.animationPath.type === AnimationPathType.Journey);
        return this.animationPath;
    }
    handleNextAnimation(reverse, initJourney) {
        const { items: animations } = this.animationPath;
        if (this.currentAnimationIndex === undefined) {
            this.currentAnimationIndex = 0;
        }
        else {
            this.currentAnimationIndex += reverse ? -1 : 1;
            if (this.animationPath.loop || this.animationPath.type !== AnimationPathType.Custom) {
                this.currentAnimationIndex = (this.currentAnimationIndex + animations.length) % animations.length;
            }
            else {
                this.currentAnimationIndex = Math.max(0, Math.min(animations.length - 1, this.currentAnimationIndex));
            }
        }
        const animation = animations[this.currentAnimationIndex];
        if (!animation)
            return;
        const itemId = animation.itemId;
        const differentSphere = animation.planogramName !== this.planogram.name || animation.language !== L10nUtils.getCurrentLanguage();
        const item = differentSphere ? undefined : this.sphereItems.findSphereItemByIdentifier(itemId);
        if (differentSphere) {
            sessionStorage.setItem(ANIMATION_PATH_KEY, JSON.stringify(this.animationPath));
            Router.navigateToPlanogramWithPath(animation.planogramName, this.animationPath.name, {
                langCode: animation.language
            }, this.currentAnimationIndex);
        }
        else if (item === undefined) {
            this.handleNextAnimation(reverse);
        }
        else {
            const isFinalItem = this.currentAnimationIndex === animations.length - 1;
            if (initJourney && this.animationPath.startWithoutNavigating) {
                this.overlay.showJourneyOverlay(this.journeyItems, this, animation.duration, this.animationPath.autoplay);
            }
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('step', this.currentAnimationIndex.toString());
            window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
            this.animateCameraToItem(itemId, () => {
                if (initJourney && !this.animationPath.startWithoutNavigating) {
                    this.overlay.showJourneyOverlay(this.journeyItems, this, animation.duration, this.animationPath.autoplay);
                }
                if (this.animationPath.type !== AnimationPathType.Custom)
                    return;
                if (isFinalItem) {
                    if (this.animationPath.applyFinalItemsAction)
                        this.handleAction(item);
                    if (this.animationPath.loop)
                        this.handleNextAnimation();
                }
                else
                    this.handleNextAnimation();
            }, {
                duration: animation.duration,
                transitionType: animation.transitionType,
                panBeforeZoom: animation.panBeforeZoom,
                zoomStopPoint: animation.zoomLevel,
                wait: this.animationPath.type !== AnimationPathType.Custom ? 0 : animation.delay
            });
        }
    }
    redirectToProduct(action, productName = '') {
        const data = this.parseProductNavigationData(action);
        if (this.planogram.name === data.planogramName) {
            searchEventHandler.emit(SEARCH_EVENTS.SHOW_SPHERE_ELEMENTS);
            this.overlay.hide();
            this.resetNavigation();
            const item = this.sphereItems.findSphereItemByIdentifier(data.productIdentifier);
            if (item) {
                this.animateToProduct(item, () => {
                    this.setProductOverlay(item.identifier, data.overlayView);
                });
                Router.navigateToProduct(item, data.overlayView);
                ``;
            }
        }
        else {
            const item = {
                planogram: {
                    name: data.planogramName
                },
                name: productName,
                identifier: data.productIdentifier
            };
            Router.navigateToProduct(item, data.overlayView);
        }
    }
    parseProductNavigationData(action) {
        const separator = '#';
        const parsedString = action.split(separator).slice(1);
        let overlayView;
        let planogramName = parsedString.shift();
        if (planogramName === "show" /* PRODUCT_PAGE_ROUTES.SHOW */) {
            overlayView = "show" /* PRODUCT_PAGE_ROUTES.SHOW */;
            planogramName = parsedString.shift();
        }
        const productIdentifier = parsedString.join('-');
        return {
            planogramName,
            productIdentifier,
            overlayView
        };
    }
    startAutoplay() {
        this.autoplayStarted = true;
        this.autoplaySpeedTime =
            this.planogram.animationSettings.autoplay_delay + this.planogram.animationSettings.duration;
        this.autoplay();
    }
    resetNavigation() {
        if (!window.location.pathname.endsWith(this.planogram.name))
            Router.navigateToPlanogram(this.planogram.name);
    }
    resetAutoplay() {
        window.clearTimeout(this.autoplayTimeoutId);
        this.autoplayStarted = false;
        this.repeatCallback = null;
        this.autoplayTimeoutId = null;
        this.cameraControls.removeAnimation(this.autoplayAnimation);
        Router.removeClusterAutoplayState();
    }
    cycleThroughClusters(direction, isAutoplay) {
        if (!(this.planogram.clustersOrder && this.planogram.clustersOrder.length)) {
            return;
        }
        const clusterOrderIndex = this.planogram.clustersOrder.indexOf(`cluster-${this.selectedCluster}`);
        const clustersMaxIndex = this.planogram.clustersOrder.length - 1;
        let nextIndex = 0;
        if (clusterOrderIndex >= 0) {
            nextIndex = clusterOrderIndex;
            if (direction === DIRECTION.RIGHT) {
                nextIndex = clusterOrderIndex + 1;
            }
            else if (direction === DIRECTION.LEFT) {
                nextIndex = clusterOrderIndex - 1;
            }
        }
        else if (direction === DIRECTION.LEFT) {
            nextIndex = clustersMaxIndex;
        }
        if (nextIndex > clustersMaxIndex) {
            nextIndex = 0;
        }
        else if (nextIndex < 0) {
            nextIndex = clustersMaxIndex;
        }
        const clusterIdentification = this.planogram.clustersOrder[nextIndex];
        Router.navigateToCluster(this.planogram.name, AppUtils.extractClusterName(clusterIdentification), {
            autoplay: isAutoplay
        });
    }
    animateCameraToClusterByArrows(direction) {
        var _a;
        switch (direction) {
            case DIRECTION.UP: {
                if (this.selectedCluster) {
                    this.animateCameraToCluster(this.selectedCluster, 0, true);
                }
                else if ((_a = this.planogram.clustersOrder) === null || _a === void 0 ? void 0 : _a.length) {
                    Router.navigateToCluster(this.planogram.name, AppUtils.extractClusterName(this.planogram.clustersOrder[0]));
                }
                break;
            }
            case DIRECTION.DOWN: {
                if (this.selectedCluster) {
                    this.cameraControls.animateZoomFov();
                }
                break;
            }
            case DIRECTION.LEFT:
            case DIRECTION.RIGHT: {
                this.cycleThroughClusters(direction);
            }
        }
    }
    animateCameraToIdentifer(identifier, openProductOverlay = true) {
        const item = this.sphereItems.findSphereItemByIdentifier(identifier);
        if (item) {
            this.animateToProduct(item, () => {
                if (openProductOverlay) {
                    this.overlay.showItem(item, "gallery" /* PRODUCT_PAGE_ROUTES.GALLERY */);
                    Router.navigateToProduct(item);
                }
            });
        }
    }
    animateToProductAfterLoad(productIdentifier, delay = 0, overlayType) {
        const item = this.sphereItems.findSphereItemByIdentifier(productIdentifier);
        const afterAnimationEnd = () => {
            CookiesManagement.isRedirectAnimationProcessing = false;
            CookiesManagement.init();
            this.overlay.showItem(item, overlayType);
        };
        CookiesManagement.isRedirectAnimationProcessing = true;
        this.animateToProduct(item, afterAnimationEnd, { duration: undefined, delay });
    }
    animateToItemAfterLoad(item, delay = 0, action, overlay) {
        const afterAnimationEnd = () => {
            var _a;
            CookiesManagement.isRedirectAnimationProcessing = false;
            CookiesManagement.init();
            if (action) {
                if (overlay) {
                    this.overlay.showItem(item);
                }
                else {
                    if (((_a = item.action) === null || _a === void 0 ? void 0 : _a.type) === ActionType.Animate) {
                        this.autoplayTimeoutId = window.setTimeout(() => {
                            this.handleAction(item);
                        }, this.planogram.animationSettings.autoplay_delay);
                    }
                    else {
                        this.handleAction(item);
                    }
                }
            }
        };
        CookiesManagement.isRedirectAnimationProcessing = true;
        this.animateToProduct(item, afterAnimationEnd, { duration: undefined, delay });
    }
    animateToImageAfterLoad(itemIdentifier, delay = 0, action) {
        const item = this.sphereItems.findSphereItemByIdentifier(itemIdentifier);
        const afterAnimationEnd = () => {
            CookiesManagement.isRedirectAnimationProcessing = false;
            CookiesManagement.init();
            if (action) {
                if (isCarouselOverlay(item.action))
                    this.overlay.showContentCarousel(item);
                else
                    this.overlay.showImage(item);
            }
        };
        CookiesManagement.isRedirectAnimationProcessing = true;
        this.animateToProduct(item, afterAnimationEnd, { duration: undefined, delay });
    }
    animateToProduct(item, afterAnimationEnd, options) {
        if (item) {
            this.cameraControls.clearAnimation();
            this.autoplayAnimation = this.cameraControls.animateTo(item, () => {
                if (afterAnimationEnd) {
                    afterAnimationEnd();
                }
            }, options);
        }
    }
    isOverlayShowing() {
        return this.overlay.isShowing();
    }
    hideOverlay(withNavigation) {
        this.overlay.hide(withNavigation);
    }
}
